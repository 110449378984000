.site-footer-wrapper {
  border-top: $divider;
  padding: 16px;

  &,
  p {
    font-size: 13px;
    margin: 0;
  }
}
