@import '../../styles/defaults/css-properties';

// Variables
$black: black;
$white: white;
$light-blue: #4ec3f7;
$light-blue-hover: #1292cc;
$blue: #2b3553;
$dark-blue: #0d101b;

$dark-gray: #0f0f0f;
$medium-gray: #666;
$light-gray: #989898;
$very-light-gray: #a9b3cf;

$body-color: $very-light-gray;
$heading-color: $white;
$link-hover-color: $white;

$divider: 1px solid #2b3553;

$transition: color 0.3s linear, background-color 0.3s linear;

$datepicker-icon-color: white;
$datepicker-icon-color-hover: $light-blue;
$datepicker-icon-color-active: black;

$selectable-height: 48px;

$calendar-divider: $divider;

$cart-divider: $divider;
$cart-background-color: #0d101b;
$cart-remove-button-icon-color: $light-blue;
$cart-remove-button-background-color: $black;

$info-color: $blue;

$radio-button-border-color: $very-light-gray;
$radio-button-checked-background-color: $light-blue;
$radio-button-checked-border-color: $light-blue;
$radio-button-selected-color: $black;

$select-arrow-color: white;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%h1 {
  font-size: 36px;

  @include large {
    font-size: 58px;
  }
}

%h2 {
  font-size: 24px;

  @include large {
    font-size: 32px;
  }
}

%button {
  text-transform: uppercase;
  transition: $transition;
}

%primary-button {
  padding: 16px 28px;
  background-color: $secondary-color;
  color: $black;
}

%primary-button-hover {
  background-color: $light-blue-hover;
  color: $white;
}

%secondary-button {
  padding: 14px 28px;
  color: $white;
  border: 1px solid $light-blue;
  background-color: transparent;
}

%secondary-button-hover {
  color: $light-blue;
  border-color: $light-blue;
}

%secondary-button-active {
  color: black;
  background-color: $light-blue;
  border-color: $light-blue;
}

%label {
  color: $very-light-gray;
  font-size: 14px;
}

%input {
  background-color: black;
  border: 1px solid $very-light-gray;
  color: $white;
  font-weight: 300;
}

%input-hover {
  border-color: $light-blue;
}

%back-button {
  text-transform: uppercase;

  &:hover {
    color: white;
  }
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-2';
@import 'calendar';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';

// Mixins
@include blurModalBackground();
@include darkThemeCheckoutPromoCodes;
