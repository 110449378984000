.stepper {
  padding: 0;
  border: 0 none;
  display: flex;
  align-items: center;

  span {
    height: auto;
    width: auto;
  }

  button {
    background: none;
    padding: $spacing-s;
    width: auto;
    height: auto;

    &:not([disabled]) {
      cursor: pointer;
    }

    svg {
      fill: $medium-gray;
      height: 16px;
    }

    &:hover,
    &:focus {
      background: none;
    }
  }
}
